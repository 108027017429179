@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.palettes {
  color: #18293b;
  color: #0078e3;
  color: #4adede;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', 'Tajawal', sans-serif;
}

html {
  height: 100%;
}

body {
  --stripe: #18293b;
  --bg: #142333;
  background: linear-gradient(135deg, var(--bg) 25%, transparent 25%) -50px 0,
    linear-gradient(225deg, var(--bg) 25%, transparent 25%) -50px 0,
    linear-gradient(315deg, var(--bg) 25%, transparent 25%),
    linear-gradient(45deg, var(--bg) 25%, transparent 25%);
  background-size: 100px 100px;
  background-color: var(--stripe);
  height: 100%;
}

.content {
  min-height: calc(100vh - 150px);
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 30px;
  text-align: center;
  color: white;
}

.detected {
  background: rgb(220 0 0 / 0.4);
  text-align: center;
  padding: 1rem;
  color: white;
  margin-bottom: -1rem;
}

.arb,
.arb p,
.arb a {
  font-family: 'Tajawal', sans-serif;
}

@media (max-width: 600px) {
  .content {
    padding: 15px;
  }
  .detected {
    margin-bottom: 0;
  }
}

.title {
  /* margin-top: -10px; */
  padding-bottom: 1rem;
  font-size: clamp(2rem, 4vw, 3rem);
}

.doctor {
  margin-top: 10px;
  font-weight: 300;
  font-size: clamp(1.2rem, 3vw, 1.8rem);
}

.teams {
  margin-top: 10px;
  font-weight: 300;
  font-size: clamp(1rem, 2vw, 1.2rem);
}

.empty {
  padding: 20px;
  margin-top: 1.5rem;
  font-size: clamp(1.4rem, 3vw, 2rem);
  border: 6px solid #0078e3;
}

@media screen and (max-width: 600px) {
  .title {
    margin-top: 0;
  }
}

.week {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 5;
  margin-top: 20px;
  border: 2px solid whitesmoke;
  padding-block: 10px;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  --stripe: #18293b;
  --bg: #142333;
  background: linear-gradient(135deg, var(--bg) 25%, transparent 25%) -50px 0,
    linear-gradient(225deg, var(--bg) 25%, transparent 25%) -50px 0,
    linear-gradient(315deg, var(--bg) 25%, transparent 25%),
    linear-gradient(45deg, var(--bg) 25%, transparent 25%);
  background-size: 100px 100px;
  background-color: var(--stripe);
  height: 100%;
}

.week > h1 {
  font-weight: 600;
}

.material {
    position: relative;
    background-color: whitesmoke;
    padding: 10px;
    padding-left: 20px;
    text-align: left;
    font-size: clamp(1.2rem, 3vw, 1.4rem);
    color: #0078e3;
}

.material>i {
    font-size: clamp(1.2rem, 3vw, 1.4rem);
    margin-right: 10px;
    width: 25px;
    text-align: center;
}


/* Customize the label (the check_container) */

.check_container {
    position: relative;
    float: right;
    margin-top: 4px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    user-select: none;
}


/* Hide the browser's default checkbox */

.check_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/* Create a custom checkbox */

.checkmark {
    position: absolute;
    right: 0;
    height: 25px;
    width: 25px;
    background-color: #ddd;
}


/* On mouse-over, add a grey background color */

.check_container:hover input~.checkmark {
    background-color: #bbb;
}


/* When the checkbox is checked, add a blue background */

.check_container input:checked~.checkmark {
    background-color: #0078e3;
}


/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.check_container input:checked~.checkmark:after {
    display: block;
}


/* Style the checkmark/indicator */

.check_container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media screen and (max-width: 768px) {
    .check_container {
        margin-top: 2px;
    }
}
footer {
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: clamp(12px, 2vw, 20px);
  letter-spacing: 1px;
  text-align: center;
  margin: auto;
  margin-bottom: 10px;
  padding: 1rem 2rem;
}

footer a {
  text-decoration: none;
  color: inherit;
}

footer a:hover {
  text-decoration: underline;
  text-underline-offset: 5px;
}

footer a::after {
  content: '';
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z'/%3E%3Cpath fill-rule='evenodd' d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}

.wrapper > h3 {
  font-size: 2rem;
  flex-basis: 100%;
}

.wrapper > i {
  font-size: 2rem;
  cursor: pointer;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-7-23 3:28:16
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.alert_circle,
.alert_circle_2 {
  position: absolute;
  left: 34px;
  z-index: 5;
  font-size: 16px;
  color: orange;
  /* color: red; */
  -webkit-animation: scale-up-center 0.5s ease-in-out infinite alternate-reverse
    both;
  animation: scale-up-center 0.5s ease-in-out infinite alternate-reverse both;
}

.alert_circle {
  margin-top: -5px;
}

.alert_circle_2 {
  margin-top: 44px;
}

.fa-bell,
.fa-exclamation {
  position: absolute;
  left: 0;
  background-color: #0078e3;
  font-size: 1.4rem;
  padding: 10px;
  padding-right: 12px;
  cursor: pointer;
  -webkit-border-top-right-radius: 10px;
  -webkit-border-bottom-right-radius: 10px;
  -moz-border-radius-topright: 10px;
  -moz-border-radius-bottomright: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 3;
}

.fa-exclamation {
  margin-top: 50px;
  padding: 10px 17px;
}

.fa-times {
  margin-right: 1rem;
}

div::-webkit-scrollbar {
  width: 0.5rem;
}

/* Track */
div::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
div::-webkit-scrollbar-thumb {
  background: #aaa;
}

/* Handle on hover */
div::-webkit-scrollbar-thumb:hover {
  background: #888;
}

/* .banner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.banner img {
  max-width: 100%;
} */

.banner img {
  object-fit: contain;
  width: 100%;
}

.ann_link {
  width: -webkit-fit-content;
  width: fit-content;
  width: -moz-fit-content;
  margin: 1rem auto;
  margin-top: 0.5rem;
  background-color: #0078e3;
  padding: 0.5rem 10vw;
  cursor: pointer;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  color: white;
  border-radius: 0.5rem;
}

.ann_link:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.ann_link a {
  color: inherit;
  font-size: clamp(1rem, 2vw, 2rem);
  text-decoration: none;
  font-weight: 600;
}

@media (max-width: 600px) {
  .ann_link:hover {
    -webkit-transform: none;
            transform: none;
  }
}

.click-to-filter {
  font-weight: 500;
  font-size: clamp(0.75rem, 2.5vw, 1.5rem);
  letter-spacing: 2px;
  margin-bottom: 0.5rem;
}

.labels i {
  font-size: 0.75rem;
  margin-right: 5px;
}

.labels h3 {
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 1.5px;
}

.labels {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0.75rem;
  gap: 0.75rem;
  border: 3px solid #ccc;
  padding: 15px;
  margin-bottom: 20px;
  font-size: 1rem;
}

@media (max-width: 800px) {
  .labels {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .labels {
    grid-template-columns: repeat(1, 1fr);
  }
}

:root {
  --cs: #ea2027;
  --sc: #78d237;
  --is: #ffd246;
  --sys: #2d73f5;
}

.course_title {
  position: relative;
  background: whitesmoke;
  color: #333;
  font-size: clamp(1rem, 2vw, 2rem);
  font-weight: 700;
  text-align: left;
  padding: 15px;
  padding-left: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.course_title:hover {
  background: #ddd;
}

.cs,
.sc,
.is,
.sys {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  bottom: 27%;
}

.cs {
  right: 140px;
  background-color: #ea2027;
  background-color: var(--cs);
}

.sc {
  right: 100px;
  background-color: #78d237;
  background-color: var(--sc);
}

.is {
  right: 60px;
  background-color: #ffd246;
  background-color: var(--is);
}

.sys {
  right: 20px;
  background-color: #2d73f5;
  background-color: var(--sys);
}

@media (max-width: 800px) {
  .course_title {
    padding: 10px;
    margin-bottom: 10px;
  }
  .warp {
    padding-right: 100px;
  }
  .cs,
  .sc,
  .is,
  .sys {
    width: 20px;
    height: 20px;
    bottom: 13px;
  }
  .cs {
    right: 82px;
  }
  .sc {
    right: 58px;
  }
  .is {
    right: 34px;
  }
  .sys {
    right: 10px;
  }
}

.issues {
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: clamp(12px, 3vw, 30px);
  margin-top: 1rem;
}

.issues a {
  font-weight: 700;
  color: white;
}

nav {
  display: flex;
  height: 80px;
  width: 100%;
  background-color: #0078e3;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%230068C4' stroke-width='1.9'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%230064BE'%3E%3Ccircle cx='769' cy='229' r='6'/%3E%3Ccircle cx='539' cy='269' r='6'/%3E%3Ccircle cx='603' cy='493' r='6'/%3E%3Ccircle cx='731' cy='737' r='6'/%3E%3Ccircle cx='520' cy='660' r='6'/%3E%3Ccircle cx='309' cy='538' r='6'/%3E%3Ccircle cx='295' cy='764' r='6'/%3E%3Ccircle cx='40' cy='599' r='6'/%3E%3Ccircle cx='102' cy='382' r='6'/%3E%3Ccircle cx='127' cy='80' r='6'/%3E%3Ccircle cx='370' cy='105' r='6'/%3E%3Ccircle cx='578' cy='42' r='6'/%3E%3Ccircle cx='237' cy='261' r='6'/%3E%3Ccircle cx='390' cy='382' r='6'/%3E%3C/g%3E%3C/svg%3E");
  align-items: center;
  justify-content: center;
}

nav .logo {
  color: #fff;
  font-size: clamp(40px, 4vw, 50px);
  font-weight: 800;
  font-family: 'Poppins';
  cursor: pointer;
  transition: letter-spacing 1s, -webkit-transform 0.2s;
  transition: transform 0.2s, letter-spacing 1s;
  transition: transform 0.2s, letter-spacing 1s, -webkit-transform 0.2s;
}

nav .logo sup {
  font-size: clamp(20px, 3.5vw, 30px);
}

nav .logo:hover {
  letter-spacing: 0.5rem;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

@media (max-width: 600px) {
  nav .logo:hover {
    letter-spacing: initial;
    -webkit-transform: none;
            transform: none;
  }
}

#not_found>h1 {
    margin-top: 100px;
    font-size: clamp(2rem, 6vw, 4rem);
}

#not_found>p {
    margin-top: 30px;
    font-size: clamp(1rem, 4vw, 1.8rem);
}

#not_found>p>code {
    background: rgba(255, 255, 255, 0.2);
    padding: 5px 10px;
    border-radius: 10px;
    letter-spacing: 2px;
}

#not_found>p>a {
    background: rgb(255, 255, 255);
    padding: 0.4rem 0.8rem;
    border-radius: 10px;
    text-decoration: none;
    line-height: 60px;
    color: #222;
}
